import * as React from "react";

const Css = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 296297 333333"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__id4"
          gradientUnits="userSpaceOnUse"
          x1={54128.7}
          y1={79355.5}
          x2={240318}
          y2={79355.5}
        >
          <stop offset={0} stopColor="#e8e7e5" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__id5"
          gradientUnits="userSpaceOnUse"
          x1={62019.3}
          y1={202868}
          x2={233515}
          y2={202868}
        >
          <stop offset={0} stopColor="#e8e7e5" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__id6"
          gradientUnits="userSpaceOnUse"
          x1={104963}
          y1={99616.9}
          x2={104963}
          y2={171021}
        >
          <stop offset={0} stopColor="#d1d3d4" />
          <stop offset={0.388} stopColor="#d1d3d4" />
          <stop offset={1} stopColor="#d1d3d4" />
        </linearGradient>
        <linearGradient
          id="prefix__id7"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#prefix__id6"
          x1={194179}
          y1={61185.8}
          x2={194179}
          y2={135407}
        />
        <mask id="prefix__id0">
          <linearGradient
            id="prefix__id1"
            gradientUnits="userSpaceOnUse"
            x1={104963}
            y1={99616.9}
            x2={104963}
            y2={171021}
          >
            <stop offset={0} stopOpacity={0} stopColor="#fff" />
            <stop offset={0.388} stopColor="#fff" />
            <stop offset={1} stopOpacity={0.831} stopColor="#fff" />
          </linearGradient>
          <path fill="url(#prefix__id1)" d="M61737 99467h86453v71704H61737z" />
        </mask>
        <mask id="prefix__id2">
          <linearGradient
            id="prefix__id3"
            gradientUnits="userSpaceOnUse"
            x1={194179}
            y1={61185.8}
            x2={194179}
            y2={135407}
          >
            <stop offset={0} stopOpacity={0} stopColor="#fff" />
            <stop offset={0.388} stopColor="#fff" />
            <stop offset={1} stopOpacity={0.831} stopColor="#fff" />
          </linearGradient>
          <path
            fill="url(#prefix__id3)"
            d="M147890 61036h92578v74521h-92578z"
          />
        </mask>
        <style>{".prefix__fil6{fill:#000;fill-opacity:.05098}"}</style>
      </defs>
      <g id="prefix__Layer_x0020_1">
        <g id="prefix___513085304">
          <path
            fill="#2062af"
            d="M268517 300922l-120369 32411-120371-32411L0 0h296297z"
          />
          <path
            fill="#3c9cd7"
            d="M148146 24374v283109l273 74 97409-26229 22485-256954z"
          />
          <path
            fill="#fff"
            d="M148040 99617l-86153 35880 2857 35524 83296-35614 88604-37883 3674-36339-92278 38432z"
          />
          <path
            mask="url(#prefix__id0)"
            fill="url(#prefix__id6)"
            d="M61887 135497l2857 35524 83295-35614V99617z"
          />
          <path
            mask="url(#prefix__id2)"
            fill="url(#prefix__id7)"
            d="M240318 61186l-92278 38431v35790l88604-37883z"
          />
          <path
            fill="url(#prefix__id5)"
            d="M62019 135497l2858 35524 127806 407-2859 47365-42055 11840-40428-10208-2450-29399H67327l4900 56756 75950 22457 75538-22050 9800-112692z"
          />
          <path
            className="prefix__fil6"
            d="M148040 135497H61888l2857 35524 83295 266v-35790zm0 95022l-408 114-40422-10208-2450-29399H67197l4899 56756 75944 22457v-39720z"
          />
          <path
            fill="url(#prefix__id4)"
            d="M54129 61186h186189l-3674 36339H58620l-4491-36339z"
          />
          <path
            className="prefix__fil6"
            d="M148040 61186H54129l4491 36339h89420z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Css;
