import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="#f03c2e"
      d="M31.396 14.575 17.425.604a2.06 2.06 0 0 0-2.914 0l-2.9 2.9 3.68 3.68a2.424 2.424 0 0 1 2.518.587 2.45 2.45 0 0 1 .581 2.533l3.547 3.547a2.445 2.445 0 0 1 2.533.582 2.45 2.45 0 1 1-3.469 3.468 2.456 2.456 0 0 1-.534-2.667l-3.308-3.308v8.705a2.5 2.5 0 0 1 .65.464 2.45 2.45 0 1 1-3.468 3.468 2.45 2.45 0 0 1 0-3.468c.237-.236.5-.415.803-.535v-8.786a2.435 2.435 0 0 1-.803-.535 2.45 2.45 0 0 1-.528-2.681l-3.63-3.628-9.58 9.57a2.06 2.06 0 0 0 0 2.915l13.972 13.97a2.06 2.06 0 0 0 2.914 0L31.396 17.5a2.06 2.06 0 0 0 0-2.915"
    />
  </svg>
)
export default SvgComponent