import React from "react";
import { motion } from "framer-motion";
import "./Blog.css";

const Blog = ({ name, date, info, link }) => {
  return (
    <motion.div
      whileHover={{
        scale: 1.1,
        transition: { repeat: Infinity, repeatType: "reverse", duration: 0.4 },
      }}
      className="blog-container"
    >
      <h2>{name}</h2>
      <p>{date}</p>
      <p>{info}</p>
      <div className="blog-links-container">
        <a className="blog-link" target="_blank" rel="noreferrer" href={link}>
          Read more...
        </a>
      </div>
    </motion.div>
  );
};

export default Blog;
