import React from "react";
import { motion } from "framer-motion";
import "./Button.css";

const buttonVariants = {
  hidden: {
    opacity: 0,
    x: "-100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: { delay: 1, duration: 1, type: "spring" },
  },
  hover: {
    scale: 1.1,
    transition: { repeat: Infinity, repeatType: "reverse", duration: 0.4 },
  },
};

const Button = (props) => {
  return (
    <motion.button
      variants={buttonVariants}
      initial="hidden"
      animate="visible"
      whileHover="hover"
      type={props.type}
      className={props.variant}
    >
      {props.children}
    </motion.button>
  );
};

export default Button;
