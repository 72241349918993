import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={64}
    height={64}
    style={{
      enableBackground: "new 0 0 64 64",
    }}
    {...props}
  >
    <path
      d="M32 21.3h21.7V0h-43v.2L32 21.3z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: "#67dbff",
      }}
    />
    <path
      d="M32 21.3H10.3v21.3h43v-.2L32 21.3z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: "#01a3ff",
      }}
    />
    <path
      d="M10.3 42.5h21.5V64L10.3 42.5z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: "#0162ff",
      }}
    />
  </svg>
)
export default SvgComponent
