import * as React from "react";

const Firebase = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 95.39 131.07"
      {...props}
    >
      <path
        d="M.05 105.75L16.18 2.52c.2-1.27 1.18-2.26 2.44-2.48s2.52.4 3.13 1.53l16.68 31.12 6.65-12.66c.51-.98 1.53-1.59 2.64-1.59s2.12.61 2.64 1.59l45.02 85.72H.05z"
        fill="#ffa000"
      />
      <path
        d="M55.78 65.54L38.43 32.68.05 105.75l55.73-40.21z"
        fill="#f57c00"
      />
      <path
        d="M95.39 105.75L83.03 29.29c-.19-1.1-.97-2-2.03-2.34s-2.22-.06-3.01.73L.05 105.75l43.12 24.18a8.94 8.94 0 008.71 0l43.51-24.18z"
        fill="#ffca28"
      />
      <path
        d="M83.03 29.29c-.19-1.1-.97-2-2.03-2.34s-2.22-.06-3.01.73L62.52 43.19 50.36 20.03c-.51-.98-1.53-1.59-2.64-1.59s-2.12.61-2.64 1.59l-6.65 12.66L21.75 1.57C21.15.44 19.88-.18 18.62.04s-2.25 1.22-2.44 2.48L.05 105.75H0l.05.06.42.21 77.49-77.58c.79-.79 1.95-1.08 3.02-.74s1.85 1.24 2.03 2.35l12.25 75.77.12-.07-12.35-76.46zM.19 105.61L16.18 3.26c.19-1.27 1.18-2.27 2.44-2.48s2.52.4 3.13 1.53l16.68 31.12 6.65-12.66c.51-.98 1.53-1.59 2.64-1.59s2.12.61 2.64 1.59l11.92 22.66L.19 105.61z"
        fill="#fff"
        fillOpacity={0.2}
      />
      <path
        d="M51.89 129.2a8.94 8.94 0 01-8.71 0L.16 105.09l-.1.66 43.12 24.18a8.94 8.94 0 008.71 0l43.5-24.18-.11-.69-43.39 24.14z"
        opacity={0.2}
        fill="#a52714"
      />
      <linearGradient
        id="prefix__a"
        gradientUnits="userSpaceOnUse"
        x1={-243.479}
        y1={345.28}
        x2={-242.436}
        y2={344.521}
        gradientTransform="matrix(95 0 0 -130.9998 23127.441 45253.934)"
      >
        <stop offset={0} stopColor="#fff" stopOpacity={0.1} />
        <stop offset={0.14} stopColor="#fff" stopOpacity={0.08} />
        <stop offset={0.61} stopColor="#fff" stopOpacity={0.02} />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <path
        d="M82.91 29.3c-.19-1.1-.97-2-2.02-2.34a2.953 2.953 0 00-3 .73L62.47 43.2 50.35 20.05c-.51-.98-1.53-1.59-2.63-1.59s-2.11.61-2.63 1.59l-6.63 12.66L21.84 1.6C21.24.47 19.98-.15 18.72.07s-2.24 1.22-2.43 2.48L.22 105.73l42.97 24.17a8.882 8.882 0 008.68 0l43.34-24.17-12.3-76.43z"
        fill="url(#prefix__a)"
      />
    </svg>
  );
};

export default Firebase;
