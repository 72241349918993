import React, { useState } from "react";
import "./Blogs.css";
import { motion } from "framer-motion";

import Blog from "./Blog/Blog";

const containerVariants = {
  hidden: {
    opacity: 0,
    x: "-100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
      when: "beforeChildren",
    },
  },
  exit: {
    x: "100vw",
    transition: { ease: "easeInOut" },
  },
};

const childrenVariants = {
  hidden: {
    opacity: 0,
    x: "-100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: "spring" },
  },
};

const Blogs = () => {
  const [blogs] = useState([
    {
      key: 1,
      name: "Sentry: Get answers for your crashes",
      date: "April 2023",
      info: "Spend less time debugging and more time developing !",
      link: "https://twoshar.medium.com/sentry-get-answers-for-your-crashes-cdf47be550e",
    },
    {
      key: 2,
      name: "Elevate React with Redux",
      date: "May 2021",
      info: "Let’s see how Redux enhances our experience using ReactJS.",
      link: "https://twoshar.medium.com/elevate-react-with-redux-dd848803f9ff?source=friends_link&sk=f64d3fa70fd640c8bd4441c62786b72a",
    },
    {
      key: 3,
      name: "React + Firebase Hosting: A Beginner’s Guide",
      date: "May 2021",
      info: "Let’s learn how to use Google Firebase Hosting to deploy your React App",
      link: "https://twoshar.medium.com/react-firebase-hosting-a-beginners-guide-69c18c56cd0b?source=friends_link&sk=e06255c5153c4e8e85564ef8ee5e239f",
    },
    {
      key: 4,
      name: "Git: Explained the ELI5 way",
      date: "May 2021",
      info: "Check out why we need the ELI5(Explain like I’m 5) explanation",
      link: "https://twoshar.medium.com/git-explained-the-eli5-way-ce75ba38d954?source=friends_link&sk=655492c8894ea46911008d9b14d98104",
    },
  ]);

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="blogs-component"
    >
      <motion.div
        variants={childrenVariants}
        className="blogs-heading-container"
      >
        <div className="blogs-heading">
          <h1>My Blogs</h1>
          <p>
            These are some of the Blogs i have really enjoyed writing and which
            i believe can be really helpful to others !
          </p>
        </div>
      </motion.div>
      <div className="blogs-container">
        {blogs.map((blog) => (
          <Blog
            key={blog.key}
            name={blog.name}
            date={blog.date}
            info={blog.info}
            link={blog.link}
          />
        ))}
      </div>
    </motion.div>
  );
};

export default Blogs;
