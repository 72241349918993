import React, { useEffect, useState } from "react";
import "./Layout.css";
import Hamburger from "../Components/UI/Hamburger/Hamburger";

import Sidebar from "../Components/Sidebar/Sidebar";
import { useLocation } from "react-router";

const Layout = (props) => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  let location = useLocation();

  useEffect(() => {
    setSidebarIsOpen(false);
  }, [location]);

  const hamburgerToggleHandler = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };

  return (
    <>
      <div className="hamburger">
        <Hamburger open={sidebarIsOpen} clicked={hamburgerToggleHandler} />
      </div>
      <Sidebar sidebarIsOpen={sidebarIsOpen} />
      <main className="content">{props.children}</main>
    </>
  );
};

export default Layout;
