import React from "react";
import "./Project.css";

const Project = ({ name, info, livelink, codelink }) => {
  return (
    <div className="project-container">
      <h3>{name}</h3>
      <p>{info}</p>
      <div className="project-links-container">
        <a
          className="project-link"
          target="_blank"
          rel="noreferrer"
          href={livelink}
        >
          View Live
        </a>
        <a
          className="project-link"
          target="_blank"
          rel="noreferrer"
          href={codelink}
        >
          View Code
        </a>
      </div>
    </div>
  );
};

export default Project;
