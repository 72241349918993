import * as React from "react";

const Html = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108.35 122.88"
      {...props}
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path
          fill="#e44d26"
          d="M108.35 0l-9.87 110.58-44.37 12.3L9.86 110.6 0 0h108.35z"
        />
        <path
          fill="#f16529"
          d="M54.17 113.48l35.86-9.94 8.43-94.5H54.17v104.44z"
        />
        <path
          d="M34.99 36.17h19.19V22.61H20.16l.32 3.64 3.33 37.38h30.35V50.06H36.23l-1.24-13.89zm3.05 34.24H24.43l1.9 21.3 27.79 7.71.06-.02V85.29l-.06.02-15.11-4.08-.97-10.82z"
          fill="#ebebeb"
        />
        <path
          d="M54.13 63.63h16.7l-1.57 17.59-15.13 4.08v14.11l27.81-7.71.2-2.29 3.19-35.71.33-3.64H54.13v13.57zm0-27.49v.03h32.76l.27-3.05.62-6.88.32-3.64H54.13v13.54z"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default Html;
