import React, { useState } from "react";
import "./Projects.css";
import { motion } from "framer-motion";

import Project from "./Project/Project";
import Button from "../UI/Button/Button";

const containerVariants = {
  hidden: {
    opacity: 0,
    x: "-100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
      when: "beforeChildren",
    },
  },
  exit: {
    x: "100vw",
    transition: { ease: "easeInOut" },
  },
};

const childrenVariants = {
  hidden: {
    opacity: 0,
    x: "-100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: "spring" },
  },
};

const Projects = () => {
  const [projects] = useState([
    {
      key: 1,
      name: "Burger Builder",
      info: "Web App built using ReactJS + Redux to help you order a custom burger",
      livelink: "https://react-my-burger-58817.web.app/",
      codelink: "https://github.com/tushardahiya/burger-builder",
    },
    {
      key: 2,
      name: "Movie Time",
      info: "A Web App made with ReactJS to check out trending movies and search for your favourites made using TheMovieDB API",
      livelink: "https://movietime-4928c.web.app/",
      codelink: "https://github.com/tushardahiya/movietime",
    },
    {
      key: 3,
      name: "tushargram",
      info: "A Photo Gallery made with ReactJS, Framer-Motion and Google Firebase",
      livelink: "https://tushargram-33038.web.app/",
      codelink: "https://github.com/tushardahiya/tushargram",
    },
    {
      key: 4,
      name: "Chat App",
      info: "Realtime Chat App made with ReactJS and Google Firebase with Authentication",
      livelink: "https://chatapp-ad863.web.app/",
      codelink: "https://github.com/tushardahiya/chatapp",
    },
    {
      key: 5,
      name: "Evernote Clone",
      info: "Evernote clone made with ReactJS, Firebase, Material UI and React Quill",
      livelink: "https://evernote-clone-f5fb8.web.app/",
      codelink: "https://github.com/tushardahiya/evernote-clone",
    },
    {
      key: 6,
      name: "CocktailsDB",
      info: "A Web App to help you find your favourite cocktails and learn more about them ",
      livelink: "https://cocktailsdb-857da.web.app/",
      codelink: "https://github.com/tushardahiya/CocktailsDB",
    },
    {
      key: 7,
      name: "TusharKart",
      info: "Amazon clone made with ReactJS, ContextAPI and firebase",
      livelink: "https://tusharkart-6a0b4.web.app/",
      codelink: "https://github.com/tushardahiya/tusharkart",
    },
    {
      key: 8,
      name: "todo App ",
      info: "A todo app made with ReactJS and Firebase",
      livelink: "https://todo-5d47c.web.app/",
      codelink: "https://github.com/tushardahiya/todo",
    },
  ]);

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="projects-page"
    >
      <motion.div
        variants={childrenVariants}
        className="projects-banner-container"
      >
        <div className="projects-banner">
          <h1>My Projects</h1>
          <p>
            Made using various libraries like ReactJS, Redux, Github, Firebase
            Authentication, Redux-thunk, Material UI, Framer Motion, React
            Quill,Google Firebase and many more,
          </p>
          <p>
            These are some of the Projects i have really enjoyed creating using
            different technologies and Libraries.
          </p>
        </div>
        <div className="projects-button-container">
          <a
            href="https://github.com/tushardahiya"
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="header-button">Check out More !</Button>
          </a>
        </div>
      </motion.div>
      <div className="projects-container">
        {projects.map((project) => (
          <Project
            key={project.key}
            name={project.name}
            info={project.info}
            livelink={project.livelink}
            codelink={project.codelink}
          />
        ))}
      </div>
    </motion.div>
  );
};

export default Projects;
