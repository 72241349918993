import React, { useEffect, useState } from "react";
import "./Contact.css";
import emailjs from "emailjs-com";
import { motion } from "framer-motion";

import Button from "../UI/Button/Button";
import {ContactLogo} from "../SVGComponents";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// for configuring react-toastify
toast.configure();

const containerVariants = {
  hidden: {
    opacity: 0,
    x: "-100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
      when: "beforeChildren",
    },
  },
  exit: {
    x: "100vw",
    transition: { ease: "easeInOut" },
  },
};

const childrenVariants = {
  hidden: {
    opacity: 0,
    x: "-100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: "spring" },
  },
};

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    setTemplateParams({
      from_name: name + "+" + email,
      to_name: "tushardahiya84@gmail.com",
      subject: subject,
      message: message,
    });
  }, [name, email, subject, message]);

  const [templateParams, setTemplateParams] = useState({
    from_name: "",
    to_name: "",
    subject: "",
    message: "",
  });

  const formSubmit = (e) => {
    e.preventDefault();
    let mailAlreadySent = sessionStorage.getItem("mailsent");
    if (mailAlreadySent !== "true") {
      emailjs
        .send(
          "service_5kjq5ht",
          "template_s6nb9yh",
          templateParams,
          "user_ZljmcUrcQsQsl2NcCah1v"
        )
        .then(() => {
          // calling the toast to be displayed
          successToast();
          sessionStorage.setItem("mailsent", "true");
          setName("");
          setEmail("");
          setSubject("");
          setMessage("");
        })
        .catch((err) => {
          // calling the toast to be displayed
          errorToast();
          console.log(err);
        });
    } else {
      warnToast();
    }
  };

  // the success toast variant
  const successToast = () => {
    toast.success("🦄 Mail Sent Successfully!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // the error toast variant
  const errorToast = () => {
    toast.error("Error occurred, Try again later", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // the toast for displaying don't spam mail
  const warnToast = () => {
    toast.warn("Please don't spam mail, Reload Browser to try again", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="contact-component"
    >
      <motion.div
        variants={childrenVariants}
        className="contact-form-container"
      >
        <h1>Contact Me</h1>
        <p>
          If you have any questions or just wanna connect with me, please leave
          a message. <br /> I would love to hear from you.
        </p>
        <form onSubmit={formSubmit}>
          <div className="form-row-one">
            <input
              type="text"
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            ></input>
            <input
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail"
            ></input>
          </div>
          <div className="form-row-two">
            <input
              type="text"
              required
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              placeholder="Subject"
            ></input>
            <textarea
              className="message-input"
              type="text"
              required
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Message"
            ></textarea>
          </div>
          <Button variant="header-button" type="submit">
            <span>Send message !</span>
          </Button>
        </form>
      </motion.div>
      <motion.div
        variants={childrenVariants}
        className="contact-logo-container"
      >
        <ContactLogo />
      </motion.div>
    </motion.div>
  );
};

export default Contact;
