import React from "react";
import "./Hamburger.css";

const Hamburger = (props) => {
  let classList = ["burger", "burger-arrow", "burger-left"];
  if (props.open) {
    classList = ["burger", "burger-arrow", "burger-left", "open"];
  }

  return (
    <div onClick={props.clicked} className={classList.join(" ")}>
      <div className="burger-lines"></div>
    </div>
  );
};

export default Hamburger;
