import React, { useEffect } from "react";

import "./HomePage.css";

import Welcome from "./Welcome/Welcome";
import Skills from "../Skills/Skills";
import Projects from "../Projects/Projects";
import Blogs from "../Blogs/Blogs";
import Contact from "../Contact/Contact";

window.addEventListener("beforeunload", () => {
  window.scrollTo(0, 0);
});

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="homepage-container">
      <Welcome />
      <Skills />
      <Projects />
      <Blogs />
      <Contact />
      <footer>Made with ❤️  using React </footer>
    </div>
  );
};

export default HomePage;
