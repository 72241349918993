import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "./Welcome.css";

import Button from "../../UI/Button/Button";

const containerVariants = {
  hidden: {
    opacity: 0,
    x: "-100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      when: "beforeChildren",
    },
  },
  exit: {
    x: "100vw",
    transition: { ease: "easeInOut" },
  },
};

const childrenVariants = {
  hidden: {
    opacity: 0,
    x: "-100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: "spring" },
  },
};

const Welcome = () => {
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="header-container"
    >
      <motion.header variants={childrenVariants}>
        <h1>Hi,</h1>
        <h1>
          I'm <motion.span>Tushar</motion.span>,
        </h1>
        <h2>Senior Software Engineer-I at Geekyants India</h2>
        <p variants={childrenVariants}>Get to know Me :) </p>
        <Link to="/contact">
          <Button variant="header-button">Contact Me !</Button>
        </Link>
      </motion.header>
    </motion.div>
  );
};

export default Welcome;
