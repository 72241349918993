import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./Sidebar.css";
import { motion } from "framer-motion";

import ProfilePicture from "../../assets/profile-picture.webp";
import {LinkedIn,GitHub} from "../SVGComponents";


const imageVariants = {
  starting: {
    scale: 0,
  },
  visible: {
    rotate: 360,
    scale: 1,
    transition: {
      type: "spring",
      duration: 1.5,
      stiffness: 50,
      damping: 5,
    },
  },
  hover: {
    scale: 1.1,
    transition: { repeat: 10, repeatType: "reverse", duration: 0.4 },
  },
};

const Sidebar = ({ sidebarIsOpen }) => {
  let classList = ["sidebar", "closed"];
  if (sidebarIsOpen) {
    classList = ["sidebar", "open"];
  }

  return (
    <div className={classList.join(" ")}>
      <div className="sidebar-banner">
        <Link to="/">
          <motion.img
            variants={imageVariants}
            initial="starting"
            animate="visible"
            whileHover="hover"
            src={ProfilePicture}
            alt="myprofileimg"
          />
        </Link>
        <div>
          <p>Web and Mobile Developer</p>
        </div>
      </div>
      <div className="sidebar-links">
        <NavLink to="skills" className="sidebar-navlink">
          <motion.p
            whileHover={{
              scale: 1.1,
              transition: {
                repeat: Infinity,
                repeatType: "reverse",
                duration: 0.3,
              },
            }}
          >
            My skills
          </motion.p>
        </NavLink>
      </div>
      <div className="sidebar-links">
        <NavLink to="projects" className="sidebar-navlink">
          <motion.p
            whileHover={{
              scale: 1.1,
              transition: {
                repeat: Infinity,
                repeatType: "reverse",
                duration: 0.3,
              },
            }}
          >
            My Projects
          </motion.p>
        </NavLink>
      </div>
      <div className="sidebar-links">
        <NavLink to="blogs" className="sidebar-navlink">
          <motion.p
            whileHover={{
              scale: 1.1,
              transition: {
                repeat: Infinity,
                repeatType: "reverse",
                duration: 0.3,
              },
            }}
          >
            My Blogs
          </motion.p>
        </NavLink>
      </div>
      <div className="sidebar-links">
        <NavLink to="contact" className="sidebar-navlink">
          <motion.p
            whileHover={{
              scale: 1.1,
              transition: {
                repeat: Infinity,
                repeatType: "reverse",
                duration: 0.3,
              },
            }}
          >
            Contact Me
          </motion.p>
        </NavLink>
      </div>
      <div className="sidebar-social-links">
        <motion.a
          whileHover={{
            scale: 1.2,
            transition: {
              repeat: Infinity,
              repeatType: "reverse",
              duration: 0.4,
            },
          }}
          href="https://www.linkedin.com/in/tushar-dahiya/"
          target="_blank"
          rel="noreferrer"
          className="sidebar-navlink"
        >
          <LinkedIn />
        </motion.a>
        <motion.a
          whileHover={{
            scale: 1.2,
            transition: {
              repeat: Infinity,
              repeatType: "reverse",
              duration: 0.4,
            },
          }}
          href="https://github.com/tushardahiya"
          target="_blank"
          rel="noreferrer"
          className="sidebar-navlink"
        >
          <GitHub />
        </motion.a>
      </div>
    </div>
  );
};

export default Sidebar;
