import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    width={2500}
    height={2500}
    viewBox="0 0 210 210"
    {...props}
  >
    <style>
      {
        ".st0{fill:#56b3b4}.st1{fill:#ea5e5e}.st2{fill:#bf85bf}.st3{fill:#f7ba3e}.st4{opacity:.5;fill:#4d616e;enable-background:new}"
      }
    </style>
    <title>{"\r\n\t\tprettier-icon-dark\r\n\t"}</title>
    <g id="Page-1">
      <g id="prettier-icon-dark">
        <g id="Icon-_x28_Dark_x29_">
          <g id="Group">
            <path
              id="Rectangle"
              d="M155 40h10c2.8 0 5 2.2 5 5s-2.2 5-5 5h-10c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st0"
            />
            <path
              id="Rectangle_1_"
              d="M5 200h50c2.8 0 5 2.2 5 5s-2.2 5-5 5H5c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st1"
            />
            <path
              id="Rectangle_2_"
              d="M125 120h30c2.8 0 5 2.2 5 5s-2.2 5-5 5h-30c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st2"
            />
            <path
              id="Rectangle_3_"
              d="M65 120h40c2.8 0 5 2.2 5 5s-2.2 5-5 5H65c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st1"
            />
            <path
              id="Rectangle_4_"
              d="M5 120h40c2.8 0 5 2.2 5 5s-2.2 5-5 5H5c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st0"
            />
            <path
              id="Rectangle_5_"
              d="M5 160h50c2.8 0 5 2.2 5 5s-2.2 5-5 5H5c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st2"
            />
            <path
              id="Rectangle_6_"
              d="M5 80h50c2.8 0 5 2.2 5 5s-2.2 5-5 5H5c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st2"
            />
            <path
              id="Rectangle_7_"
              d="M55 20h100c2.8 0 5 2.2 5 5s-2.2 5-5 5H55c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st3"
            />
            <path
              id="Rectangle_8_"
              d="M5 20h30c2.8 0 5 2.2 5 5s-2.2 5-5 5H5c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st1"
            />
            <path
              id="Rectangle_9_"
              d="M45 180h10c2.8 0 5 2.2 5 5s-2.2 5-5 5H45c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st3"
            />
            <path
              id="Rectangle_10_"
              d="M45 60h10c2.8 0 5 2.2 5 5s-2.2 5-5 5H45c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st0"
            />
            <path
              id="Rectangle_11_"
              d="M5 180h20c2.8 0 5 2.2 5 5s-2.2 5-5 5H5c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st0"
            />
            <path
              id="Rectangle_12_"
              d="M5 60h20c2.8 0 5 2.2 5 5s-2.2 5-5 5H5c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st3"
            />
            <path
              id="Rectangle_13_"
              d="M135 180h10c2.8 0 5 2.2 5 5s-2.2 5-5 5h-10c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st4"
            />
            <path
              id="Rectangle_14_"
              d="M75 180h40c2.8 0 5 2.2 5 5s-2.2 5-5 5H75c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st4"
            />
            <path
              id="Rectangle_15_"
              d="M165 180h40c2.8 0 5 2.2 5 5s-2.2 5-5 5h-40c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st4"
            />
            <path
              id="Rectangle_16_"
              d="M85 100h80c2.8 0 5 2.2 5 5s-2.2 5-5 5H85c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st0"
            />
            <path
              id="Rectangle_17_"
              d="M35 100h30c2.8 0 5 2.2 5 5s-2.2 5-5 5H35c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st3"
            />
            <path
              id="Rectangle_18_"
              d="M5 100h10c2.8 0 5 2.2 5 5s-2.2 5-5 5H5c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st1"
            />
            <path
              id="Rectangle_19_"
              d="M95 40h40c2.8 0 5 2.2 5 5s-2.2 5-5 5H95c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st2"
            />
            <path
              id="Rectangle_20_"
              d="M5 40h70c2.8 0 5 2.2 5 5s-2.2 5-5 5H5c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st0"
            />
            <path
              id="Rectangle_21_"
              d="M35 140h90c2.8 0 5 2.2 5 5s-2.2 5-5 5H35c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st3"
            />
            <path
              id="Rectangle_22_"
              d="M5 140h10c2.8 0 5 2.2 5 5s-2.2 5-5 5H5c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st2"
            />
            <path
              id="Rectangle_23_"
              d="M125 60h50c2.8 0 5 2.2 5 5s-2.2 5-5 5h-50c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st1"
            />
            <path
              id="Rectangle_24_"
              d="M125 80h50c2.8 0 5 2.2 5 5s-2.2 5-5 5h-50c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st3"
            />
            <path
              id="Rectangle_25_"
              d="M5 0h120c2.8 0 5 2.2 5 5s-2.2 5-5 5H5c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st0"
            />
            <path
              id="Rectangle_26_"
              d="M145 0h60c2.8 0 5 2.2 5 5s-2.2 5-5 5h-60c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st4"
            />
            <path
              id="Rectangle_27_"
              d="M145 140h20c2.8 0 5 2.2 5 5s-2.2 5-5 5h-20c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st4"
            />
            <path
              id="Rectangle_28_"
              d="M185 140h20c2.8 0 5 2.2 5 5s-2.2 5-5 5h-20c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st4"
            />
            <path
              id="Rectangle_29_"
              d="M175 120h30c2.8 0 5 2.2 5 5s-2.2 5-5 5h-30c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st4"
            />
            <path
              id="Rectangle_30_"
              d="M175 20h30c2.8 0 5 2.2 5 5s-2.2 5-5 5h-30c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st4"
            />
            <path
              id="Rectangle_31_"
              d="M185 100h20c2.8 0 5 2.2 5 5s-2.2 5-5 5h-20c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st4"
            />
            <path
              id="Rectangle_32_"
              d="M185 40h20c2.8 0 5 2.2 5 5s-2.2 5-5 5h-20c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st4"
            />
            <path
              id="Rectangle_33_"
              d="M195 60h10c2.8 0 5 2.2 5 5s-2.2 5-5 5h-10c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st4"
            />
            <path
              id="Rectangle_34_"
              d="M195 80h10c2.8 0 5 2.2 5 5s-2.2 5-5 5h-10c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st4"
            />
            <path
              id="Rectangle_35_"
              d="M115 160h90c2.8 0 5 2.2 5 5s-2.2 5-5 5h-90c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st4"
            />
            <path
              id="Rectangle_36_"
              d="M75 160h20c2.8 0 5 2.2 5 5s-2.2 5-5 5H75c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st4"
            />
            <path
              id="Rectangle_37_"
              d="M155 200h50c2.8 0 5 2.2 5 5s-2.2 5-5 5h-50c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st4"
            />
            <path
              id="Rectangle_38_"
              d="M75 200h60c2.8 0 5 2.2 5 5s-2.2 5-5 5H75c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st4"
            />
            <path
              id="Rectangle_39_"
              d="M75 60h30c2.8 0 5 2.2 5 5s-2.2 5-5 5H75c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st4"
            />
            <path
              id="Rectangle_40_"
              d="M75 80h30c2.8 0 5 2.2 5 5s-2.2 5-5 5H75c-2.8 0-5-2.2-5-5s2.2-5 5-5z"
              className="st4"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
export default SvgComponent
