import React from "react";
import "./Skills.css";
import { motion } from "framer-motion";

import {
  Axios,
  CplusPlus,
  CSS,
  EsLint,
  Firebase,
  Framer,
  Git,
  GitHub,
  GitLab,
  Html,
  JavaScript,
  Jest,
  Mobx,
  NextJs,
  Prettier,
  ReactLogo,
  ReactNative,
  Redux,
  TailwindCSS,
  TypeScript,
  Vitest,
} from "../SVGComponents";

const containerVariants = {
  hidden: {
    opacity: 0,
    x: "-100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      when: "beforeChildren",
    },
  },
  exit: {
    x: "100vw",
    transition: { ease: "easeInOut" },
  },
};

const childrenVariants = {
  hidden: {
    opacity: 0,
    x: "-100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: "spring" },
  },
};

const Skills = () => {
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="skills-container"
    >
      <div className="left-container">
        <motion.h1 variants={childrenVariants}>
          My Skills and Experience{" "}
        </motion.h1>
        <p>
          I'm a software developer with over two years of experience,
          specializing in crafting React Native Apps and React Websites. My
          dedication to delivering high-quality software solutions and creating
          efficient, user-focused applications sets me apart in the field.
        </p>
        <p>
          In my current role, I collaborate effectively in multidisciplinary
          teams, utilizing cutting-edge technologies to develop and launch React
          Native applications and websites.
        </p>
        <p>
          I have experience creating platform-agnostic components that
          seamlessly operate in both web and mobile applications. I've also
          demonstrated an aptitude for optimizing Android and iOS apps, leading
          to significant improvements in startup and load times.
        </p>
        <p>
          Outside of my professional life, I enjoy playing Badminton and
          traveling. My passion for excellence, technical expertise, and
          commitment to delivering user-centric and secure software solutions
          make me a valuable asset in the software development field.
        </p>
        <p>
          To know more about me, you can visit my{" "}
          <a
            href="https://www.linkedin.com/in/tushar-dahiya/"
            target="_blank"
            rel="noreferrer"
          >
            linkedIn
          </a>{" "}
          profile.
        </p>
      </div>
      <div className="right-container">
        <div className="logos-container">
          <Axios />
          <CplusPlus />
          <EsLint />
          <Framer />
          <Git />
          <GitLab />
          <Jest />
          <Mobx />
          <NextJs />
          <Prettier />
          <ReactNative />
          <TailwindCSS />
          <TypeScript />
          <Vitest />
          <ReactLogo />
          <JavaScript />
          <Firebase />
          <Redux />
          <GitHub />
          <Html />
          <CSS />
        </div>
        <div className="experience-container">
          <div>
            <h2>GeekyAnts India Pvt Ltd</h2>
            <h4>Senior Software Engineer - I</h4>
            <p className="experience-date">Aug 2023 - Current</p>
            <h4>Software Engineer - III</h4>
            <p className="experience-date">Aug 2022 - July 2023</p>
            <h4>Software Engineer - I</h4>
            <p className="experience-date">Aug 2021 - July 2022</p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Skills;
